$sizes: 4, 8, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80;
$fontWeight: 400, 500, 600, 700, 800, 900;
$objectFit: 'none', 'contain', 'cover';
$objectPosition: 'center', 'top', 'bottom';

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        @if $breakpoint == "xs" {
            @each $size in $sizes {
                .fs-#{$size} {
                    font-size: #{$size/16}rem !important;
                }

                .lh-#{$size} {
                    line-height: #{$size/16}rem !important;
                }
            }
        } @else {
            @each $size in $sizes {
                .fs-#{$breakpoint}-#{$size} {
                    font-size: #{$size/16}rem !important;
                }

                .lh-#{$breakpoint}-#{$size} {
                    line-height: #{$size/16}rem !important;
                }
            }
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        @if $breakpoint == "xs" {
            @each $fw in $fontWeight {
                .fw-#{$fw} {
                    font-weight: #{$fw} !important;
                }
            }

            @each $op in $objectPosition {
                .op-#{$op} {
                    object-position: #{$op} !important;
                }
            }
        } @else {
            @each $fw in $fontWeight {
                .fw-#{$breakpoint}-#{$fw} {
                    font-weight: #{$fw} !important;
                }
            }

            @each $op in $objectFit {
                .op-#{$breakpoint}-#{$op} {
                    object-position: #{$op} !important;
                }
            }
        }
    }
}


.text-right {
    text-align: right;
}

.text-nunito {
    font-family: $nunito;
}

.text-roboto {
    font-family: $roboto;
}

.ls-1 {
    letter-spacing: 0.25px;
}

.ls-2 {
    letter-spacing: 0.5px;
}

.ls-3 {
    letter-spacing: 0.75px;
}

.ls-4 {
    letter-spacing: 1px;
}

.ls-5 {
    letter-spacing: 1.5px;
}

.ls-6 {
    letter-spacing: 2px;
}

.color-brown1 {
    color: $brown1;
}

.color-red1 {
    color: $red1;
}

.color-red2 {
    color: $red2;
}

.color-red3 {
    color: $red3;
}

.color-primary {
    color: $primary;
}

.color-secondary {
    color: $secondary;
}

.color-blue {
    color: $blue;
}

.text-justify {
    text-align: justify;
}

.bg-primary-alt {
    background-color: rgba(26, 44, 121, 0.15);
}

.page-bg {
    background: url('../images/page-bg.png') no-repeat;
    background-size: cover;
    background-position: 0 -35px;
    padding-bottom: 100px;
}

.max-width-100 {
    max-width: 100%;
}

.text-outline {
    display: flex;
    align-items: center;

    &::after {
        content: '';
        width: 20px;
        height: 2px;
        background: $secondary;
        margin-left: 6px;
        display: none;
    }
}

.object-cover {
    object-fit: cover;
}

.as-1x1 {
    position: relative;
    &::after {
        padding-bottom: 100%;
        content: '';
        display: block;
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.dot-shape-top {
    top: -5px;
    z-index: -1;
    left: -10px;
}

.dot-shape {
    bottom: -3px;
    left: auto;
    right: -3px;
    z-index: -1;
}
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.launch {
    margin: 0px;
    overflow: hidden;
    font-family: 'Lexend Deca', sans-serif;
    text-align: center;
    height: 100vh;
    width: 100vw;
    padding: 24px;
    background: url('../images/bg.jpg') #fff;
    color: #fff;
    font-size: 16px;


    .maintainance-img {
        max-width: 500px;
        width: 100%;
    }
    h1{
        font-weight: 600;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        font-size: 40px;
    }
    p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 20px;
    }
    .launch-logo {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .launch-logo img {
        max-width: 400px;
        width: 100%;
        background-size: cover;
        margin-bottom: 1.5rem;
    }
    article {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    address {
        margin-top: 1.5rem;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin: 0;
        + address {
            margin-top: 0.5rem;
        }
    }

    a {
        color: #fff;
        font-style: normal;
        font-weight: 400;
        text-decoration: none;
    }
    #timer {
        font-size: 60px;
        font-family: 'Bebas Neue', sans-serif;
        text-shadow: 5px 3px rgba(0,0,0,0.35);
    }
    @media (max-width: 960px) {
        body {
            background-position: left top;
            padding: 1rem;
        }
        #timer {
            font-size: 40px;
        }
        .launch-logo img {
            max-width: 250px;
            margin-bottom: 1.5rem;
        }
        h1{
            font-size: 30px;
        }
        p {
            font-size: 18px;
        }
    }
}
@import 'variables';
@import "~bootstrap/scss/mixins";

.hero-banner {
    width: 100%;
    overflow: hidden;
    .slide-item {
        height: 500px;
        position: relative;
        overflow: hidden;
        img {
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            transition: all 1s ease-in-out;
            transform: scale(1.25, 1.25);
            object-fit: cover;
        }

        &-overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            font-size: 55px;
            color: #fff;
            text-transform: capitalize;
            line-height: 1.3;
            width: 78vw;

            @include media-breakpoint-down(md) {
                width: 100vw;
                font-size: 40px;
                padding: 0 1.5rem;
                text-align: center !important;
                br {
                    display: none;
                }
            }
        }

        &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #000;
            z-index: 1;
            content: '';
            opacity: 0.4;
        }
    }

    .slick-active {
        img {
          transform: scale(1, 1);
        }

        .text-blur {
            color: transparent;
            animation: blur 10s ease-in-out;
            -webkit-animation: blur 10s ease-in-out;
        }

        .text-blur:nth-child(1) {
            animation-delay: 0.05s;
            -webkit-animation-delay: 0.05s;
        }
        .text-blur:nth-child(2) {
            animation-delay: 0.2s;
            -webkit-animation-delay: 0.2s;
        }
        .text-blur:nth-child(3) {
            animation-delay: 0.3s;
            -webkit-animation-delay: 0.3s;
        }
        .text-blur:nth-child(4) {
            animation-delay: 0.4s;
            -webkit-animation-delay: 0.4s;
        }
        .text-blur:nth-child(5) {
            animation-delay: 0.5s;
            -webkit-animation-delay: 0.5s;
        }
        .text-blur:nth-child(6) {
            animation-delay: 0.6s;
            -webkit-animation-delay: 0.6s;
        }
    }

    .slick-arrow {
        z-index: 1;
        width: 48px;
        height: 48px;
        opacity: 1;
        transition: 0.3s opacity linear;
        background: #fff;
        border-radius: 100%;

        &:before {
            display: none;
        }

        &.slick-next {
            right: 24px;
        }

        &.slick-prev {
            left: 24px;
        }

        @include media-breakpoint-down(md) {
            display:  none !important;
        }
    }

    .slick-dots {
        bottom: 32px;
        li {
            width: 12px;
            height: 12px;
            background: rgba($color: #fff, $alpha: 0.75);
            border-radius: 100%;
            margin: 0 8px;

            &.slick-active {
                background: #000;
            }
        }
    }
}

.list-with-numbers {
    list-style: none;
    padding: 0;
    margin: 0 0 0 0;

    li {
        margin-bottom: 24px;
    }

    span {
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
        background: $secondary;
        border-radius: 100%;
        color: $white;
    }
}

.our-customers {
    &-slick {
        border-radius: 30px;
        background: #fff;
        padding: 24px;
        box-shadow: 0px 2px 9px 0px rgba(68, 68, 68, 0.1);
        border: 1px solid #f1f1f1;

        &-item {
            text-align: center;
            height: 130px;
            display: flex !important;
            justify-content: center;
            align-items: center;

            img {
                max-width: 80%;
                max-height: 100px;
            }
        }
    }
}

@keyframes blur {
	0%		{text-shadow:  0 0 100px #fff; opacity:0;}
	5%		{text-shadow:  0 0 90px #fff;}
	15%		{opacity: 1;}
	20%		{text-shadow:  0 0 0px #fff;}
	80%		{text-shadow:  0 0 0px #fff;}
	85%		{opacity: 1;}
	95%		{text-shadow:  0 0 90px #fff;}
	100%	{text-shadow:  0 0 100px #fff; opacity:0;}
}

@-webkit-keyframes blur {
	0%		{text-shadow:  0 0 100px #fff; opacity:0;}
	5%		{text-shadow:  0 0 90px #fff;}
	15%		{opacity: 1;}
	20%		{text-shadow:  0 0 0px #fff;}
	80%		{text-shadow:  0 0 0px #fff;}
	85%		{opacity: 1;}
	95%		{text-shadow:  0 0 90px #fff;}
	100%	{text-shadow:  0 0 100px #fff; opacity:0;}
}

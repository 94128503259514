@import 'variables';

.footer {
    background: $blue url('../images/maps.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 2% 0;
    color: white;

    .logo {
        max-width: 250px;
    }

    &-top {
        ul {
            list-style: none;
            li {
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            a {
                color: #fff;
                text-decoration: none;
                font-size: 14px
            }
        }
    }

    &-bottom {
        background: rgba($color: #fff, $alpha: 0.05);
        &-social {
            a {
                display: inline-flex;
                margin-left: 32px;
                &:first-child {
                    margin-left: 0;
                }
            }
            img {
                width: 16px;
                height: 16px;
            }
        }
    }
}
@import 'variables';
@import "~bootstrap/scss/mixins";

.logo {
    max-width: 250px;

    img {
        width: 100%
    }

    @include media-breakpoint-down(lg) {
        max-width: 150px;
    }
}

.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    transition: 0.3s all ease;
    background: rgba($color: #fff, $alpha: 1);
    border-bottom: 1px solid #f1f1f1;

    .nav {

        ul.main-nav {
            margin: 0;
            list-style: none;
            display: flex;

            li {
                padding: 8px 12px;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;

                &:last-child {
                    margin-right: 0;
                }

                &::after {
                    content: '';
                    height: 2px;
                    width: 100%;
                    background-color: $secondary;
                    width: 0;
                    transition: 0.3s all linear;
                    order: 2;
                }

                &.active::after {
                    width: 100%;
                }

                .header-dropdown-menu {
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    background: $white;
                    order: 3;
                }

                &.header-dropdown-item > a{
                    order: 1;
                }
            }

            a {
                text-decoration: none;
            }
        }

        .header-dropdown-item {
            > span {
                cursor: pointer;
            }

            &:hover {
                background: $white;

                .header-dropdown-menu {
                    display: flex;
                }
            }

            .header-dropdown-menu {
                display: flex;
                padding: 1rem;
                flex-direction: column;
                flex-wrap: wrap;
                white-space: nowrap;

                a {
                    margin-bottom: 1rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        ul.social-nav {
            list-style: none;
            display: flex;
            padding: 0;
            li {
                width: 60px;
                text-align: center;
                border-left: 1px solid $primary;
            }
            img {
                width: 16px;
                height: 16px;
            }
        }
    }

    &.sticky {
        background: $white;
        box-shadow: 4px 4px 5px 2px rgba($color: #000, $alpha: 0.12);
    }
}

.hb-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0px;
    position: relative;
    z-index: 2;

    > div {
        width: 2rem;
        height: 0.25rem;
        background: #333;
        border-radius: 10px;
        transition: all 0.4s linear 0s;
        position: relative;
        transform-origin: 1px center;

        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
            opacity: 1;
            transform: translateX(0px);
        }
    }
}

.nav-overlay {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(lg) {
        position: fixed;
        right: -100vw;
        top: 0;
        background: rgba($color: #000, $alpha: 0.12);
        display: flex !important;
        align-items: start;
        width: 100%;
        height: 100%;
        justify-content: end;
        transition: background 0.3s linear;;
        ul {
            background: $white;
            height: auto;
            width: auto;
            flex-direction: column;
            padding-top: 60px !important;
            position: fixed;
            right: -100vw;
            top: 0;
            transition: right 0.4s ease-in-out;
            li {
                align-items: start !important;
            }

            .header-dropdown-menu {
                display: flex !important;
                flex-direction: column !important;
                position: relative !important;
                height: auto;
                top: 0 !important;
            }
        }
    }
}

.header-top .active {
    .hb-menu {
        div:first-child {
            transform: rotate(45deg);
        }

        div:nth-child(2) {
            opacity: 0;
            transform: translateX(20px);
        }

        div:nth-child(3) {
            transform: rotate(-45deg);
        }
    }

    @include media-breakpoint-down(lg) {
        .nav-overlay {
            right: 0;
            ul {
                right: 0;
                height: 100vh;
            }
        }

        .header-dropdown-menu {
            padding-bottom: 0 !important;
        }
    }
}
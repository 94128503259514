@import "bootstrap/scss/functions";

$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 769px,
    lg: 992px,
    xl: 1240px
) !default;

$container-max-widths: (
    md: 100%,
    lg: 100%,
    xl: 1240px
) !default;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer* .75,
  4: $spacer * 1,
  5: $spacer * 1.25,
  6: $spacer * 1.5,
  7: $spacer* 1.75,
  8: $spacer * 2,
  9: $spacer * 2.25,
  10: $spacer * 2.5,
  11: $spacer* 2.75,
  12: $spacer * 3,
) !default;

$nunito: 'Nunito Sans', sans-serif;
$roboto: 'Roboto Slab', serif;


$red1: #c50614;
$red2: #f20516;
$red3: #a80b12;
$white: #fff;
$black1: #202020;
$black2: #353535;
$primary: #333333;
$secondary: #FF3B00;

$brown1: #462b34;
$blue: #03041c;
$grey1: #967d7b;

$grid-gutter-width: 3rem !default;